<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-membership-card-96.png"
          class="h-20 ml-2 inline-block"
        />
        خرید اشتراک در این کانال
      </h2>
      <h2 class="text-xl font-bold">
        <Icon name="Group" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.gid }}</div>
    </template>
    <template #default>
      <p class="text-lg font-bold" v-if="!channel.accounts.length">
        در این گروه اشتراکی برای خرید وجود ندارد. میتوانید از مدیر گروه درخواست کنید که
        اشتراک ایجاد کند
      </p>
      <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <div
          v-for="account in channel.accounts"
          :key="account.id"
          class="bg-blueGray-50 border border-blueGray-200 p-3 rounded-md"
        >
          <div class="border-b border-blueGray-200 pb-3">
            <img
              src="@/assets/images/icons8-membership-card-96.png"
              class="inline-block h-10"
            />
            {{ account.name }}
          </div>
          <div class="flex p-2">
            <div class="w-1/2">قیمت</div>
            <div class="w-1/2 text-left">{{ $number.format(account.price) }} تومان</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">مدت</div>
            <div class="w-1/2 text-left">{{ account.days }} روز</div>
          </div>
          <div class="p-2">
            {{ account.info }}
          </div>
          <Button class="mt-3 text-lg" color="teal" @click="submit(account.id)">
            خرید
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      channel: {},
      sending: false,
      // L_: _,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get(`/api/user-channels/${$this.$route.params.channelId}/accounts`, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
    submit(id) {
      // console.log(id);
      let $this = this;
      $this.sending = true;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .post(`/api/user-channels/${$this.$route.params.channelId}/accounts`, {
          id: $this.$store.state.user.id,
          accountId: id,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "ChannelUserAccountsCheck",
            params: {
              id: $this.$store.state.user.id,
              channelId: $this.$route.params.channelId,
              payId: response.data.pay.id,
            },
          });
          // $this.group = response.data.group;
        })
        .catch(function (error) {
          // console.log(error);
          $this.sending = false;
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>
